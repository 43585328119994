<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ name: `enquiryManagement${$route.meta.policyType}` }"
        >询价单管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>编辑询价单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <enquiryForm
        ref="enquiryForm"
        :detailData="enquiryDetail"
        :permissions="policyDetailPermissions"
      />
    </div>
    <div class="operating-box">
      <el-button size="mini" @click="handleBack">返回</el-button>
      <el-button
        size="mini"
        type="primary"
        @click="handleReset"
        plain
        icon="el-icon-refresh-left"
        >重置</el-button
      >
      <el-button
        size="mini"
        type="warning"
        plain
        icon="el-icon-receiving"
        v-if="$route.params.type == 1"
        @click="handlePrecheck(1)"
        >保存</el-button
      >
      <!-- <el-button
        size="mini"
        type="primary"
        plain
        icon="el-icon-top"
        @click="showUpload = true"
        v-if="$route.meta.policyType === 1"
        >上传附件</el-button
      > -->
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-circle-check"
        @click="handlePrecheck(0)"
        >提交</el-button
      >
      <!-- <el-button size="mini" type="warning" icon="el-icon-document">生成投保单</el-button> -->
    </div>
    <DragMultipleUpload
      :show.sync="showUpload"
      :fileSzie="10"
      :dataEcho="dataEcho"
      @submitData="handleSubmitAttachment"
    ></DragMultipleUpload>
  
    <ReferralFeeDialog
      :show.sync="ReferralFeeShow"
      @submit="handleSubmitReferralFee"
      :detail="{
        referralFee: enquiryDetail.referralFee || 0,
        referralFeeSwitch: enquiryDetail.referralFeeSwitch || false,
      }"
    ></ReferralFeeDialog>
  </div>
</template>

<script>
import enquiryForm from "@/views/EnquiryManagement/components/form.vue";
import DragMultipleUpload from "@/components/DragMultipleUpload";
import { hasPerms, dataPermissions } from "@/utils/auth";
import ReferralFeeDialog from "./components/ReferralFeeDialog.vue";
import {
  saveEnquiryList,
  insNewEnquiryDetail,
  tempSaveEnquiryList,
  OrderReferralSwitch,
} from "@/api/policy";

export default {
  components: { enquiryForm, DragMultipleUpload, ReferralFeeDialog },
  data() {
    return {
      enquiryDetail: {},
      originalEnquiryDetail: {},
      policyDetailPermissions: {
        agentId: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "agentName"
        ),
        // "desc": "代理人",
        applicantMode: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "applicantMode"
        ),
        // "desc": "投保方式",
        lastPolicyNo: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "lastPolicyNo"
        ),
        // "desc": "上一年保单号",
        endDate: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "endDate"
        ),
        // "desc": "到期时间",
        city: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "city"
        ),
        // "desc": "所属地区",
        province: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "province"
        ),
        // "desc": "所属省份",
        applicantType: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "applicantType"
        ),
        // "desc": "投保类型",
        acceptCompanyList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "acceptCompanyList"
        ),
        // "desc": "承保公司",
        extensionClause: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "extensionClause"
        ),
        // "desc": "免费扩展条款",
        specialAgreement: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "specialAgreement"
        ),
        // "desc": "特别约定",
        applicantStore: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "applicantStore"
        ),
        // "desc": "投保门店",
        absoluteDeductible3: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "absoluteDeductible3"
        ),
        // "desc": "未扩展不计免赔",
        absoluteDeductible2: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "absoluteDeductible2"
        ),
        // "desc": "扩展不计免赔2",
        salesmanId: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "salesmanName"
        ),
        // "desc": "业务员",
        agentNo: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "agentNo"
        ),
        // "desc": "代理人编码",
        beneficiaryList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "beneficiaryList"
        ),
        // "desc": "受益人",
        policyHolderList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policyHolderList"
        ),
        // "desc": "投保人",
        totalPremium: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "totalPremium"
        ),
        // "desc": "总保费",
        ep: dataPermissions(`M26_${this.$route.meta.policyType}-View`, "ep"),
        // "desc": "电子保单",
        projectRemark: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "projectRemark"
        ),
        // "desc": "项目备注",
        policyPlanList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policyPlanList"
        ),
        // "desc": "保障计划",
        policySubjectList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policySubjectList"
        ),
        // "desc": "标的信息",
        absoluteDeductible: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "absoluteDeductible"
        ),
        // "desc": "扩展不计免赔1",
        policyInsuredList: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "policyInsuredList"
        ),
        // "desc": "被保险人",
        projectName: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "projectName"
        ),
        // "desc": "项目名称",
        startDate: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "startDate"
        ),
        // "desc": "起保时间",
        enquiryNo: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "enquiryNo"
        ),
        // "desc": "询价单号",
        recorder: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "recorder"
        ),
        // "desc": "录单员",
        specialVersion: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "specialVersion"
        ),
        // "desc": "特别说明",
        bindUser: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "bindUser"
        ),
        // 绑定客户
        salesmanDeptName: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "salesmanDeptName"
        ),
        // "desc": "所属部门",
        sourceChannel: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "sourceChannel"
        ),
        // "desc": "业务渠道",
        upstreamAgentId: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "upstreamAgent"
        ),
        // "desc": "业务代理人",
        insuredCategory: dataPermissions(
          `M26_${this.$route.meta.policyType}-View`,
          "insuredCategory"
        ),
        // "desc": "险种类型",
      },
      showUpload: false,
      dataEcho: {},
      attachmentData: {},
      ReferralFeeShow: false,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      insNewEnquiryDetail(this.$route.params.id)
        .then((res) => {
          this.enquiryDetail = res.data;
          this.originalEnquiryDetail = JSON.parse(JSON.stringify(res.data));
          let dataBackShow = [];
          let idInd = 1;
          res.data.attachments
            ? Object.keys(res.data.attachments).forEach(function (key) {
                let type = (key + "").split("-");
                dataBackShow.push({
                  type: type[0] == "qt" ? undefined : type[0],
                  selectValue: Number(type[1] || 0),
                  urls: res.data.attachments[key].map((val) => {
                    idInd++;
                    return {
                      suffix: val.fileType,
                      name: val.fileName,
                      url: val.url,
                      id: idInd,
                    };
                  }),
                });
              })
            : res.data.attachments = {
          kpxx: [],
          qtxx: [],
          zjxx: [],
        };
          this.dataEcho = dataBackShow;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmitAttachment(e) {
      let attachment = {};
      let attachmentType = [];
      let qtInd = 1;
      e.map((val) => {
        if (val.type) {
          attachmentType.push(val.type + "-" + val.selectValue);
          attachment[val.type + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
        } else {
          attachmentType.push("qt" + qtInd + "-" + val.selectValue);
          attachment["qt" + qtInd + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
          qtInd++;
        }
      });
      this.attachmentData = {
        attachments: attachment,
        attachmentType: attachmentType.toString(),
      };
      this.showUpload = false;
    },
    async handleSubmitEnquiry(obj, type) {
      // let data = await this.$refs.enquiryForm.handleSubmit(type);
      let data = this.handleSequence(obj, [
        "beneficiaryList",
        "policyHolderList",
        "policyInsuredList",
      ]);
      if (this.$route.meta.policyType == 3) {
        delete data.policySubjectList;
      }
      if (this.$route.meta.policyType != 1) {
        data.attachmentType = "zjxx,qtxx,yyzz,kpxx";
      } else if (this.attachmentData.attachments) {
        let attachmentType = [];
        for (const key in this.attachmentData.attachments) {
          attachmentType.push(key);
        }
        data.attachmentType = attachmentType.toString();
      } else {
        data.attachmentType = "";
      }
      if (type == 1) {
        tempSaveEnquiryList({
          category: this.$route.meta.policyType,
          ...data,
          ...this.attachmentData,
        }).then((res) => {
          this.handleBack();
          this.$message.success(res.mes || "保存成功");
        });
      } else {
        saveEnquiryList({
          category: this.$route.meta.policyType,
          enquiryType: type,
          ...data,
          ...this.attachmentData,
        }).then((res) => {
          this.handleBack();
          this.$message.success(res.mes || "保存成功");
        });
      }
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleReset() {
      this.attachmentData = {};
      this.enquiryDetail = JSON.parse(
        JSON.stringify(this.originalEnquiryDetail)
      );
      let dataBackShow = {};
      let idInd = 1;
      for (const key in this.enquiryDetail.attachments) {
        let type = (key + "").split("-");
        dataBackShow[type[0]] = {
          type: type[0] == "qt" ? undefined : type[0],
          selectValue: Number(type[1]),
          urls: this.enquiryDetail.attachments[key].map((val) => {
            idInd++;
            return {
              suffix: val.fileType,
              name: val.fileName,
              url: val.url,
              id: idInd,
            };
          }),
        };
      }
      this.dataEcho = dataBackShow;
      this.$forceUpdate();
    },
    handleSequence(obj = {}, list = [], sort = "sort") {
      if (list && list.length) {
        list.forEach((item) => {
          if (obj[item].length) {
            obj[item].forEach((p, i) => {
              p[sort] = i;
            });
          }
        });
      }
      return obj;
    },
    async handlePrecheck(type) {
      let data = await this.$refs.enquiryForm.handleSubmit(type);
      this.resEnquiryForm = data;
      if (type == 1 || this.$route.meta.policyType !== 1) {
        this.handleSubmitEnquiry(this.resEnquiryForm, type);
        return;
      }
      //校验业务推荐费
      let {
        projectId,
        schemeName,
        policyInsuredList = [],
        salesmanDeptId,
      } = data;
      OrderReferralSwitch({
        cardNoList: policyInsuredList.map((item) => {
          return item.cardNo;
        }),
        projectId,
        programmeName: schemeName,
        deptId: salesmanDeptId,
      }).then((res) => {
        if (res.data.floatFlag == '1') {
          this.referralFeeObj = res.data;
          this.ReferralFeeShow = true;
          //console.log("需要业务推荐费");
        } else {
          this.referralFeeObj = {};
          this.handleSubmitEnquiry(data, type);
        }
      });
    },
      
    //业务推荐费校验后逻辑
    handleSubmitReferralFee(data) {
      if (
        data.referralFee > this.referralFeeObj.individualFee &&
        data.referralFeeSwitch
      ) {
        this.$message.warning(
          '不能超过方案中 初始方案 的 "个人绩效费率"' +
            this.referralFeeObj.individualFee +
            "%"
        );
      } else {
        this.ReferralFeeShow = false;
        this.handleSubmitEnquiry({ ...this.resEnquiryForm, ...data }, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
